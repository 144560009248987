<template>
  <div id="creatNums">
    <el-card style="position: relative">
      <!-- 最近拨打号码记录 -->
      <el-card class="box-card" :style="callRecord ? 'width: 96%;' : 'width: 0%'">
        <div slot="header" class="clearfix" v-show="callRecord">
          <span style="font-size: 16px; font-weight: bold">最近拨打记录</span>
          <el-button style="float: right; padding: 3px 0" type="text" @click="callRecord = false">收起</el-button>
        </div>
        <el-table v-show="callRecord" :data="callRecordTableData" :class="filterEnterprise()" class="table"
          ref="multipleTable" header-cell-class-name="table-header" @selection-change="handleSelectionChange" height="120"
          @row-dblclick="dblclick">
          <!-- <el-table-column type="index" width="50" align="center"></el-table-column> -->
          <!-- <el-table-column type="selection" width="55" align="center"></el-table-column> -->
          <!-- <el-table-column prop="userRealname" label="归属人" align="center"></el-table-column> -->
          <el-table-column prop="activePhone" label="手机号码" align="center" width="120" fixed
            v-if="(!hideNum && id != 5051) || roleName == '企业'"></el-table-column>
          <el-table-column prop="phone" label="手机号码" align="center" width="120" fixed
            v-if="(hideNum || id == 5051) && roleName != '企业'"></el-table-column>

          <el-table-column :prop="v.prop" :label="v.label" align="center" v-for="v in newColumnsData" :key="v.key"
            width="160"></el-table-column>

          <!-- <el-table-column prop="company" label="企业名称" align="center"></el-table-column> -->
          <!-- <el-table-column prop="customerPhone" label="姓名" align="center"></el-table-column> -->
          <!-- <el-table-column prop="industry" label="行业" align="center"></el-table-column> -->
          <!-- <el-table-column prop="address" label="地址" align="center"></el-table-column> -->
          <el-table-column prop="status" label="状态" align="center" width="100" fixed="right">
            <template #default="scope">
              <span v-if="scope.row.status == 0">一般客户</span>
              <span v-if="scope.row.status == 1">意向客户</span>
              <span v-if="scope.row.status == 2">无意向客户</span>
              <span v-if="scope.row.status == 3">成交客户</span>
              <span v-if="scope.row.status == 4">黑名单</span>
            </template>
          </el-table-column>
          <!-- <el-table-column prop="createTime" label="导入时间" align="center"  fixed="right"></el-table-column> -->
          <!-- <el-table-column prop="changeTime" label="拨打时间" align="center"></el-table-column> -->
          <!-- <el-table-column prop="remarks" label="备注" align="center" class="hide"></el-table-column> -->
          <!-- <el-table-column prop="choosePeople" label="决策人" align="center"></el-table-column> -->
          <!-- <el-table-column prop="customerScale" label="公司规模" align="center"></el-table-column> -->
          <!-- <el-table-column prop="customerSource" label="客户来源" align="center"></el-table-column> -->
          <!-- <el-table-column label="操作" width="280" align="center" fixed="right">
                <template #default="scope">
                  <el-button type="success" @click.stop="call(scope)" v-if="roleName != '企业'" size="mini">拨打
                  </el-button>
                  <el-button type="success" @click="addBtn(scope)" v-if="roleName != '企业'" size="mini">短信提醒跟进
                  </el-button>
                  <el-button type="danger" @click="removeButton(scope.row, scope.$index)" size="mini">释放
                  </el-button>
                </template>
              </el-table-column> -->
        </el-table>
      </el-card>

      <el-button size="medium" @click="callRecord = true" class="box-card" style="" round
        v-show="!callRecord">展开</el-button>
      <header>
        <h2 style="display: flex; align-items: center">
          <el-page-header @back="goBack" title="返回上页" content="个人私海"></el-page-header>
          <span v-if="roleName == '企业' && showTitleText">(查看组长端、员工端【个人私海】已拨打的全部数据)</span>
          <span v-if="roleName == '组长' && showTitleText">(查看组员个人私海和自己端个人私海，未拨打的数据)</span>
          <span v-if="roleName == '人员' && showTitleText">(查看员工端【个人私海】已拨打的全部数据)</span>
          <el-tooltip class="item" effect="dark" content="点击查看详情说明" placement="top">
            <img v-if="!showTitleText" src="@/assets/images/what.png" alt=""
              style="width: 30px; height: 30px; cursor: pointer" @click="showTitleText = true" />
          </el-tooltip>
        </h2>
        <div class="search">
          <div class="left">
            <div class="left-wrap">
              <span>号码</span>
              <el-input v-model="activePhone" placeholder="请输入完整号码" size="mini" clearable>
                <el-button slot="append" icon="el-icon-search" @click="handleSearch"></el-button>
              </el-input>
            </div>

            <div style="margin-left: 10px">
              <el-button size="mini" v-show="isDivVisible" @click="setisDivVisible(false)">取消高级搜索</el-button>
              <el-button type="success" v-show="!isDivVisible" plai size="mini"
                @click="isDivVisible = true">高级搜索</el-button>
            </div>
            <div>
              <el-button v-show="!showBtnList" style="margin-left: 10px" type="success" @click="showBtnList = true"
                size="mini" class="searchBtn">更多设置
              </el-button>

              <div v-show="showBtnList" class="transition-box" style="margin-left: 10px">
                <el-button v-show="showBtnList" icon="el-icon-d-arrow-right" size="mini"
                  @click="showBtnList = false">收起</el-button>
                <el-button type="success" icon="el-icon-search" @click="handleSearch" size="mini"
                  class="searchBtn">查找</el-button>
                <el-button class="reset" type="success" icon="el-icon-refresh" @click="reset()" size="mini">重置</el-button>
                <el-button type="success" @click="distributeNums()" size="mini" class="distributeNums"
                  v-if="roleName != '人员'">分配号码</el-button>
                <el-button type="success" icon="el-icon-folder-opened" @click="allNums()" size="mini"
                  class="allNums">批量导入号码</el-button>
                <el-button type="success" icon="el-icon-folder-opened" @click="addNums()" size="mini"
                  class="addNums">添加号码</el-button>
                <el-tooltip content="释放/批量释放：将员工已拨打的数据进行释放到【部门公海】、【公司公海】进行二次分配且只有公司公海能回收到回收站进行删除" effect="light"
                  placement="top">
                  <el-button type="danger" @click="moreDelete()" class="delete" size="mini" icon="el-icon-delete">批量释放
                  </el-button>
                </el-tooltip>
              </div>
            </div>

            <!-- <div class="left-wrap">
              <span>客户来源</span>
              <el-input v-model="customerSource" placeholder="请输入客户来源" size="mini"></el-input>
            </div>
            <div class="left-wrap" v-if="realname == 'admin'">
              <span>{{ getNewLabel('企业', 'company') }}</span>
              <el-input v-model="company" :placeholder="`请输入${getNewLabel('企业', 'company')}`" filterable
                size="mini"></el-input>
            </div>
            <div class="left-wrap" v-if="roleName == '组长'">
              <span>员工</span>
              <el-select v-model="id1" placeholder="请选择员工" filterable size="mini">
                <el-option v-for="item in underUser" :key="item.id" :label="item.realname" :value="item.id">
                </el-option>
              </el-select>
            </div>
            <div class="left-wrap" v-if="roleName == '管理员' || roleName == '企业'">
              <span>部门</span>
              <el-select v-model="departId" placeholder="请选择部门" filterable size="mini">
                <el-option v-for="item in underDepart" :key="item.id" :label="item.departName" :value="item.id">
                </el-option>
              </el-select>
            </div>
            <div class="left-wrap" v-if="roleName == '管理员' || roleName == '企业'">
              <span>员工</span>
              <el-select v-model="userId" placeholder="请选择员工" filterable size="mini">
                <el-option v-for="item in underUser" :key="item.id" :label="item.realname" :value="item.id">
                </el-option>
              </el-select>
            </div>
            <div class="left-wrap">
              <span>导入时间</span>
              <el-date-picker v-model="createTime" type="datetimerange" range-separator="-" start-placeholder="开始日期"
                end-placeholder="结束日期" align="center" size="mini" :picker-options="pickerOptions">
              </el-date-picker>
            </div> -->
            <!-- <div class="left-wrap">
              <span>拨打时间</span>
              <el-date-picker v-model="changeTime" type="datetimerange" range-separator="-" start-placeholder="开始日期"
                end-placeholder="结束日期" align="center" size="mini" :picker-options="pickerOptions">
              </el-date-picker>
            </div> -->
            <!-- <div class="left-wrap">
              <span>更新时间</span>
              <el-date-picker v-model="getTime" type="datetimerange" range-separator="-" start-placeholder="开始日期"
                end-placeholder="结束日期" align="center" size="mini" :picker-options="pickerOptions">
              </el-date-picker>
            </div> -->
          </div>
          <transition name="el-zoom-in-top">
            <div v-show="isDivVisible" class="transition-box left vipSearch">
              <div class="left-wrap" v-if="roleName == '管理员' || roleName == '企业'">
                <span>员工</span>
                <el-select v-model="userId" placeholder="请选择员工" filterable size="mini">
                  <el-option v-for="item in underUser" :key="item.id" :label="item.realname" :value="item.id">
                  </el-option>
                </el-select>
              </div>
              <div class="left-wrap" v-if="roleName == '组长'">
                <span>员工</span>
                <el-select v-model="id1" placeholder="请选择员工" filterable size="mini">
                  <el-option v-for="item in underUser" :key="item.id" :label="item.realname" :value="item.id">
                  </el-option>
                </el-select>
              </div>
              <div class="left-wrap">
                <span>客户来源</span>
                <el-select v-if="ifParentId == 7" v-model="customerSource" placeholder="请选择客户来源" filterable size="mini">
                  <el-option v-for="(item, index) in customerSourceOptions" :key="index" :label="item" :value="item">
                  </el-option>
                </el-select>
                <el-input v-else v-model="customerSource" placeholder="请输入客户来源" size="mini"></el-input>
              </div>
              <div class="left-wrap">
                <span>备注</span>
                <el-input v-model="notes" placeholder="请输入备注" size="mini"></el-input>
              </div>
              <div class="left-wrap" v-if="realname == 'admin'">
                <span>{{ getNewLabel("企业", "company") }}</span>
                <el-input v-model="company" :placeholder="`请输入${getNewLabel('企业', 'company')}`" filterable
                  size="mini"></el-input>
              </div>
              <div class="left-wrap">
                <span>{{ getNewLabel("企业", "company") }}</span>
                <el-input v-model="company" :placeholder="`请输入${getNewLabel('企业', 'company')}`" size="mini"></el-input>
              </div>
              <div class="left-wrap">
                <span>{{ getNewLabel("行业", "industry") }}</span>
                <el-input v-model="industry" :placeholder="`请输入${getNewLabel('行业', 'industry')}`" size="mini"></el-input>
              </div>

              <div class="left-wrap" v-if="roleName == '管理员' || roleName == '企业'">
                <span>部门</span>
                <el-select v-model="departId" placeholder="请选择部门" filterable size="mini">
                  <el-option v-for="item in underDepart" :key="item.id" :label="item.departName" :value="item.id">
                  </el-option>
                </el-select>
              </div>

              <div class="left-wrap">
                <span>导入时间</span>
                <el-date-picker v-model="createTime" type="datetimerange" range-separator="-" start-placeholder="开始日期"
                  end-placeholder="结束日期" align="center" size="mini" :picker-options="pickerOptions">
                </el-date-picker>
              </div>
            </div>
          </transition>
          <div class="right"></div>
        </div>
      </header>
      <main>
        <el-tabs v-model="activeData" @tab-click="clickData">
          <el-tab-pane label="未拨打" name="wbd">
            <el-table :data="openedArrObj[cutIndex]" :class="filterEnterprise()" ref="multipleTable"
              header-cell-class-name="table-header" @selection-change="handleSelectionChange" @row-dblclick="dblclick">
              <!-- @row-dblclick="dblclick" -->
              <el-table-column type="selection" width="55" align="center"></el-table-column>
              <el-table-column prop="activePhone" label="手机号码" align="center" fixed width="120"
                v-if="(!hideNum && id != 5051) || roleName == '企业'"></el-table-column>
              <el-table-column prop="phone" label="手机号码" align="center" fixed width="120"
                v-if="(hideNum || id == 5051) && roleName != '企业'"></el-table-column>
              <!-- <el-table-column prop="company" label="企业名称" align="center"></el-table-column>
              <el-table-column prop="customerPhone" label="姓名" align="center"></el-table-column>
              
              <el-table-column prop="industry" label="行业" align="center"></el-table-column>
              <el-table-column prop="address" label="地址" align="center"></el-table-column> -->

              <!-- <el-table-column :label="v.label" v-for="v in newColumnsData" :key="v.key">
                <el-input v-model="currentRow[v.prop]" placeholder="无" disabled></el-input>
              </el-table-column> -->

              <el-table-column prop="status" label="状态" align="center" width="100">
                <template #default="scope">
                  <span v-if="scope.row.status == 0">一般客户</span>
                  <span v-if="scope.row.status == 1">意向客户</span>
                  <span v-if="scope.row.status == 2">无意向客户</span>
                  <span v-if="scope.row.status == 3">成交客户</span>
                  <span v-if="scope.row.status == 4">黑名单</span>
                  <span v-if="scope.row.status == 5">无状态</span>
                  <span v-if="scope.row.status == 6">未接通</span>
                </template>
              </el-table-column>
              <el-table-column :prop="v.prop" :label="v.label" align="center" v-for="v in newColumnsData" :key="v.key"
                width="160"></el-table-column>
              <!-- <el-table-column prop="createTime" label="导入时间" align="center"></el-table-column>
              <el-table-column prop="changeTime" label="拨打时间" align="center"></el-table-column>
              <el-table-column prop="remarks" label="备注" align="center" class="hide"></el-table-column>
              <el-table-column prop="choosePeople" label="决策人" align="center"></el-table-column>
              <el-table-column prop="customerScale" label="公司规模" align="center"></el-table-column>
              <el-table-column prop="customerSource" label="客户来源" align="center"></el-table-column> -->
              <el-table-column label="操作" width="160" align="center" fixed="right">
                <template #default="scope">
                  <el-button type="success" @click.stop="call(scope)" v-if="roleName != '企业'" size="mini">拨打</el-button>
                  <!-- 释放号码 -->
                  <el-button type="danger" @click="removeButton(scope.row, scope.$index)" size="mini">释放</el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
          <el-tab-pane label="已拨打" name="ybd">
            <el-table :data="openedArrObj[cutIndex]" :class="filterEnterprise()" ref="multipleTable"
              header-cell-class-name="table-header" @selection-change="handleSelectionChange" @row-dblclick="dblclick">
              <el-table-column type="selection" width="55" align="center"></el-table-column>
              <el-table-column prop="activePhone" label="手机号码" align="center" fixed width="120"
                v-if="(!hideNum && id != 5051) || roleName == '企业'"></el-table-column>
              <el-table-column prop="phone" label="手机号码" align="center" fixed width="120"
                v-if="(hideNum || id == 5051) && roleName != '企业'"></el-table-column>
              <el-table-column prop="status" label="状态" align="center" width="100">
                <template #default="scope">
                  <span v-if="scope.row.status == 0">一般客户</span>
                  <span v-if="scope.row.status == 1">意向客户</span>
                  <span v-if="scope.row.status == 2">无意向客户</span>
                  <span v-if="scope.row.status == 3">成交客户</span>
                  <span v-if="scope.row.status == 4">黑名单</span>
                  <span v-if="scope.row.status == 5">无状态</span>
                  <span v-if="scope.row.status == 6">未接通</span>
                </template>
              </el-table-column>
              <el-table-column :prop="v.prop" :label="v.label" align="center" v-for="v in newColumnsData" :key="v.key"
                width="160"></el-table-column>

              <el-table-column label="操作" width="300" align="center" fixed="right" v-if="roleName != '企业'">
                <template #default="scope">
                  <el-button type="success" @click.stop="call(scope)" size="mini">拨打</el-button>
                  <!-- <el-button type="danger" @click="removeButton(scope.row, scope.$index)" size="mini">释放</el-button> -->
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
        </el-tabs>
      </main>
    </el-card>
    <!--分页  -->
    <div class="pagination">
      <el-pagination background layout="total, prev, pager, next,sizes,jumper" :current-page="pageIndex"
        :page-size="pageSize" :page-sizes="[10, 20, 50, 100, 200, 300]" :total="pageTotal" @size-change="handleSizeChange"
        @current-change="handlePageChange"></el-pagination>
    </div>
    <!-- 编辑弹出框 -->
    <el-dialog title="编辑" :visible.sync="updateVisible" :before-close="updateClose">
      <el-form ref="form" :model="form" label-width="100px" class="update">
        <el-form-item label="客户号码">
          <el-input v-model="form.phone" placeholder="请输入客户号码"
            v-if="(hideNum || id == 5051) && roleName != '企业'"></el-input>
          <el-input v-model="form.activePhone" placeholder="请输入客户号码"
            v-if="(!hideNum && id != 5051) || roleName == '企业'"></el-input>
        </el-form-item>

        <!-- <el-form-item :label="v.label" v-for="v in newColumnsData" :key="v.key">
          <el-input v-model="form[v.prop]" placeholder="无" disabled></el-input>
        </el-form-item> -->

        <el-form-item :label="v.label" v-for="v in editColumnsData" :key="v.key">
          <div v-if="ifParentId == 7 && v.label == '客户来源'">
            <el-select v-model="form.customerSource" placeholder="请选择客户来源" filterable>
              <el-option v-for="(item, index) in customerSourceOptions" :key="index" :label="item" :value="item">
              </el-option>
            </el-select>
          </div>
          <div v-else>
            <el-input v-model="form[v.prop]" :placeholder="`请输入${v.label}`"></el-input>
          </div>
        </el-form-item>
        <!-- <el-form-item label="客户姓名">
          <el-input v-model="form.customerPhone" placeholder="请输入客户姓名"></el-input>
        </el-form-item>
        <el-form-item label="公司名称">
          <el-input v-model="form.company" placeholder="请输入公司名称"></el-input>
        </el-form-item>

        <el-form-item label="决策人">
          <el-input v-model="form.choosePeople" placeholder="请输入决策人"></el-input>
        </el-form-item>
        <el-form-item label="公司规模">
          <el-input v-model="form.customerScale" placeholder="请输入公司规模"></el-input>
        </el-form-item>
        <el-form-item label="客户来源">
          <el-input v-model="form.customerSource" placeholder="请输入客户来源"></el-input>
        </el-form-item>
        <el-form-item label="行业">
          <el-input v-model="form.industry" placeholder="请输入行业"></el-input>
        </el-form-item>
        <el-form-item label="地址">
          <el-input v-model="form.address" placeholder="请输入地址"></el-input>
        </el-form-item> -->
        <el-form-item label="状态">
          <el-radio-group v-model="update_status" style="line-height: 30px">
            <el-radio :label="0">一般客户</el-radio>
            <el-radio :label="1">意向客户</el-radio>
            <el-radio :label="2">无意向客户</el-radio>
            <el-radio :label="3">成交客户</el-radio>
            <el-radio :label="4">黑名单</el-radio>
            <el-radio :label="5">无状态</el-radio>
            <el-radio :label="6">未接通</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="跟进记录">
          <el-input type="textarea" v-model="form.historyRecord" placeholder="请输入跟进记录(备注)"></el-input>
        </el-form-item>
      </el-form>
      <template #footer style="position: relative">
        <span class="dialog-footer" style="display: flex">
          <el-button type="success" @click="updateSubmit" style="width: 90%">确 定</el-button>
          <el-button type="success" @click="updateCallTime" style="width: 10%">更新</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 释放弹出框 -->
    <el-dialog title="释放" :visible.sync="removeVisible" width="30%">
      <el-form ref="form" :model="form" label-width="100px">
        <el-form-item label="释放位置">
          <el-select v-model="removeValue" @change="oneSlt">
            <el-option v-for="item in options2" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="success" @click="removeVisible = false">取 消</el-button>
          <el-button type="success" @click="confirm()" v-if="removeValue == 1">确 定</el-button>
          <el-button type="success" @click="depDetails" v-else-if="removeValue == 0" :disabled="disabled1">下一步</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 部门选择弹出框 -->
    <el-dialog title="释放" :visible.sync="depVisible" width="30%">
      <el-form ref="form" :model="form" label-width="100px">
        <el-form-item label="释放位置">
          <el-select v-model="removeParams.departId" placeholder="请选择释放位置" filterable>
            <el-option v-for="item in freeDepart" :key="item.id" :label="item.departName" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="success" @click="(removeVisible = true), (depVisible = false)">上一步</el-button>
          <el-button type="success" @click="step">确 定</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 批量释放弹出框 -->
    <el-dialog title="批量释放" :visible.sync="batchRemoveVisible" width="30%">
      <el-form ref="form" :model="form" label-width="100px">
        <el-form-item label="释放位置">
          <el-select v-model="batchRemoveValue" @change="oneSltBatch">
            <el-option v-for="item in options2" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="success" @click="batchRemoveVisible = false">取 消</el-button>
          <el-button type="success" @click="confirm1()" v-if="batchRemoveValue == 1">确 定</el-button>
          <el-button type="success" @click="depDetails1" v-else-if="batchRemoveValue == 0"
            :disabled="disabled2">下一步</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 批量部门选择弹出框 -->
    <el-dialog title="批量释放" :visible.sync="depVisibleBatch" width="30%">
      <el-form ref="form" :model="form" label-width="100px">
        <el-form-item label="释放位置">
          <el-select v-model="departId" placeholder="请选择释放位置" filterable>
            <el-option v-for="item in freeDepart" :key="item.id" :label="item.departName" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="success" @click="(batchRemoveVisible = true), (depVisibleBatch = false)">上一步</el-button>
          <el-button type="success" @click="step1()">确 定</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 添加号码弹出框 -->
    <el-dialog title="添加号码" :visible.sync="addOneVisible">
      <el-form ref="form" :model="form" label-width="100px" class="update">
        <el-form-item label="客户号码">
          <el-input v-model="form.activePhone" placeholder="请输入客户号码"></el-input>
        </el-form-item>
        <el-form-item label="客户姓名">
          <el-input v-model="form.customerPhone" placeholder="请输入客户姓名"></el-input>
        </el-form-item>
        <el-form-item label="公司名称" v-if="realname == 'admin'">
          <el-input v-model="form.company" placeholder="输入公司名称分配至公司公海"></el-input>
        </el-form-item>
        <el-form-item label="部门">
          <el-input v-model="form.departName" placeholder="输入部门名称分配至部门海"></el-input>
        </el-form-item>
        <el-form-item label="决策人">
          <el-input v-model="form.choosePeople" placeholder="请输入决策人"></el-input>
        </el-form-item>
        <el-form-item label="公司规模">
          <el-input v-model="form.customerScale" placeholder="请输入公司规模"></el-input>
        </el-form-item>
        <el-form-item label="公司名称">
          <el-input v-model="form.company" placeholder="请输入公司名称"></el-input>
        </el-form-item>
        <el-form-item label="客户来源">
          <el-select v-if="ifParentId == 7" v-model="form.customerSource" placeholder="请选择客户来源" filterable>
            <el-option v-for="(item, index) in customerSourceOptions" :key="index" :label="item" :value="item">
            </el-option>
          </el-select>
          <el-input v-else v-model="form.customerSource" placeholder="请输入客户来源"></el-input>
        </el-form-item>

        <el-form-item label="行业">
          <el-input v-model="form.industry" placeholder="请输入行业"></el-input>
        </el-form-item>
        <el-form-item label="地址">
          <el-input v-model="form.address" placeholder="请输入地址"></el-input>
        </el-form-item>
        <el-form-item label="状态">
          <el-radio-group v-model="update_status" style="line-height: 30px">
            <el-radio :label="0">一般客户</el-radio>
            <el-radio :label="1">意向客户</el-radio>
            <el-radio :label="2">无意向客户</el-radio>
            <el-radio :label="3">成交客户</el-radio>
            <el-radio :label="4">黑名单</el-radio>
            <el-radio :label="5">无状态</el-radio>
            <el-radio :label="6">未接通</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="备注">
          <el-input v-model="form.remarks" placeholder="请输入备注"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="success" @click="addOneVisible = false">取 消</el-button>
          <el-button type="success" @click="addOneSubmit">确 定</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 批量导入号码弹出框 -->
    <el-dialog title="批量导入号码" :visible.sync="numShow" width="70%" class="num-upload" @close="clearNumber">
      <div class="left">
        <div style="display: flex; align-items: center; margin-bottom: 10px">
          <div style="margin-right: 10px">号码检测</div>
          <el-switch style="display: block" v-model="numberTest" active-color="#13ce66" inactive-color="#ff4949"
            active-text="开启" inactive-text="关闭">
          </el-switch>
        </div>
        <el-button type="success" icon="el-icon-download" @click="upload()" class="uploadBtn" style="width: 100%">点击下载模板
        </el-button>
        <el-upload ref="uplaod" class="upload-demo" action="" :on-preview="handlePreview" :on-remove="handleRemove"
          :before-remove="beforeRemove" :auto-upload="false" multiple :limit="1" :on-exceed="handleExceed"
          :file-list="fileList" :on-change="changeUpload" :drag="true" width="100%" accept=".xls,.xlsx">
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        </el-upload>
      </div>
      <div class="right">
        <div style="line-height: 36px; color: #606369" v-if="roleName == '企业'">
          <p>
            1、不论在管理员账号的（客户列表、个人私海、部门公海、公司公海）导入客户名单，在导入表格里面只填写了手机号码，那么最终都会导入到【公司公海】
          </p>
          <p>
            2、不论在管理员账号的（客户列表、个人私海、部门公海、公司公海）导入客户名单，在导入表格里面填写了手机号、以及坐席账户、部门名字，最终都会到对应的【部门公海】
          </p>
          <p style="color: red">
            注意：如需要把客户名单导入部门，则必须由管理员创建部门后，才能够成功导入部门，反之则不能；如导入数据，部门名称填写错误，会默认导入公司公海
          </p>
        </div>
        <div style="line-height: 36px; color: #606369" v-if="roleName == '组长'">
          <p>
            1、不论从组长账户的（客户列表、个人私海、部门公海）导入客户名单，在导入表格里面只填写了手机号，那么最终都会导入到组长的【个人私海】
          </p>
          <p>
            2、不论从组长账户的（客户列表、个人私海、部门公海）导入客户名单，在导入表格里面填写了手机号、以及坐席账户、本部门名字，最终都会导入到组长的【部门公海】
          </p>
          <p>
            3、开启号码检测后将自动过滤掉无效的号码,未开通(即使开启)没有过滤号码的效果,需要开通则联系客服或管理员
          </p>
          <p style="color: red">
            注意：如需要把客户名单导入部门，则必须由管理员创建部门后，才能够成功导入部门，反之则不能。在导入表格里面填写了手机号、以及坐席账户、本部门名字，最终都会导入到组长的【部门公海】，在进行号码分配
          </p>
        </div>
        <div style="line-height: 36px; color: #606369" v-if="roleName == '人员'">
          <p>
            1、不论从员工账户的（客户列表、个人私海）导入客户名单，在导入表格里面只填写了手机号，那么最终都会导入到坐席的【个人私海】
          </p>
          <p>
            2、不论从员工账户的（客户列表、个人私海）导入客户名单，在导入表格里面填写了手机号、以及坐席账户、本部门名字，最终都会导入到员工的【部门公海】
          </p>
          <p>
            3、开启号码检测后将自动过滤掉无效的号码,未开通(即使开启)没有过滤号码的效果,需要开通则联系客服或管理员
          </p>
          <p style="color: red">
            注意：如需要把客户名单导入部门，则必须由管理员创建部门后，才能够成功导入部门，反之则不能。员工端登录自己的账号，【批量导入号码】--下载模版，模板里手机号必填，其他按照需求填写保存，将文件拖到导入号码提示框里面导入
          </p>
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <!-- <el-button @click="numShow = false">取 消</el-button> -->
          <el-button @click="numsSubmit" :disabled="disabled" type="success" style="width: 100%">确 定</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 分配号码弹出框 -->
    <el-dialog title="分配号码" :visible.sync="distributeShow" width="30%" @close="hidenDistribute">
      <el-switch v-model="departUser" active-text="选择个人" inactive-text="选择部门" v-if="roleName != '组长'">
      </el-switch>
      <el-form ref="form" label-width="100px" style="margin-top: 30px">
        <el-form-item label="部门" v-if="roleName != '组长'">
          <el-select v-model="departId" placeholder="请选择部门，分配至部门海" :disabled="departUser" filterable
            v-if="roleName != '组长'">
            <el-option v-for="item in underDepart" :key="item.id" :label="item.departName" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="个人">
          <el-select v-model="userId" filterable placeholder="请选择个人" :disabled="!departUser">
            <el-option v-for="item in underUser" :key="item.id" :label="item.realname" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="distributeShow = false" style="margin-right: 10px">退 出</el-button>
        <span class="dialog-footer">
          <el-button type="success" @click="distributeSubmit">确认提交</el-button>
        </span>
      </template>
    </el-dialog>

    <el-dialog title="回拨已拨打" :visible.sync="callVisible" width="30%" center>
      <span style="
          display: flex;
          justify-content: center;
          align-items: center;
          color: red;
        ">请在20秒内用手机拨打:{{ xphone }}</span>
    </el-dialog>

    <!-- sip弹出框 -->
    <el-dialog title="SIP呼叫" :visible.sync="sipCallVisible" v-if="sipCallVisible" width="30%" :before-close="handleClose"
      @close="hang_up">
      <sipCall :callPhoneNum="sipPhone" :callPhoneData="callData" @showValue="showMethod"></sipCall>
    </el-dialog>
  </div>
</template>

<script>
import { confirmUserOnline, selectDzwlUserApi } from "@/api/dzwl";
// 号码检测api
import { find, list } from "@/api/NnumberDetection";
import sipCall from "@/views/sip/sipCall.vue";
import clientsCharts from "@/views/charts/clientsCharts.vue";
import { ElMessage } from "@/assets/js/elementui-api";
let Message = new ElMessage();

import * as XLSX from "xlsx";
import XlsxPopulate from "xlsx-populate";

import {
  phoneFindOneHistoryPhone,
  phoneFindUser,
  phoneList,
  phoneDistribute,
  phoneDelete,
  phoneUpdate,
  phoneImportPhone,
  phoneImportPhoneOld,
  phoneAdd,
} from "@/api/phone";
import { sysDepartList } from "@/api/sys_depart";
import { callAppBind, update_call } from "@/api/call";

export default {
  components: { clientsCharts, sipCall },
  data() {
    return {
      ifParentId: null,
      notes: null,
      timer: null,
      showBtnList: false,
      isDivVisible: false,
      callButton: false,
      pickerOneDate: "",
      pickerOptions: {
        // 获取选择的第一个时间
        onPick: ({ maxDate, minDate }) => {
          this.pickerOneDate = minDate.getTime();
          if (maxDate) {
            this.pickerOneDate = "";
          }
        },
        disabledDate: (time) => {
          //选择了第一个时间的情况下根据第一个时间设置可选范围
          //未选择第一个时间的情况下可选范围只能选择过去的时间
          if (this.pickerOneDate) {
            const day = 30 * 24 * 3600 * 1000; // 15天
            let maxTime = this.pickerOneDate + day;
            let minTime = this.pickerOneDate - day;
            return time.getTime() > maxTime || time.getTime() < minTime;
          } else {
            // return time.getTime() > Date.now()
          }
        },
      },
      newColumnsData: [],
      editColumnsData: [],
      callRecordTableData: [],
      numberTest: false,
      showTitleText: false,
      ua: "",
      callData: null, // 拨号后接口数据
      sipCallVisible: false,
      updateVisible: false,
      activeData: "wbd",
      activeName: "update",
      update_status: null,
      fileList: [],
      depVisible: false,
      depVisibleBatch: false,
      disabled1: true,
      disabled2: true,
      options3: [
        {
          value: 0,
          label: "a",
        },
        {
          value: 1,
          label: "b",
        },
      ],
      options2: [
        {
          value: 0,
          label: "部门公海",
        },
        {
          value: 1,
          label: "公司公海",
        },
      ],
      callRecord: true,
      textarea: "",
      drawer: false,
      direction: "rtl",
      choosePeople: null,
      customerScale: null,
      customerSource: null,
      createTime: null,
      changeTime: null,
      getTime: null,
      removeIndex: [],
      sipPhone: null,
      callNum: null,
      industry: null,
      address: null,
      id: null,
      id1: null,
      oneNum: 0,
      twoNum: 0,
      activePhone: null,
      company: null,
      value: null,
      disabled: false,
      removeParams: {}, //释放列表时需要的参数
      removeValue: "", // 释放选择框选中的项
      batchRemoveValue: "", // 批量释放选择框选中的项
      removeVisible: false, //释放弹出框
      batchRemoveVisible: false, //批量释放弹出框
      addOneVisible: false, //添加弹出框
      distributeShow: false, //分配弹出框
      departUser: true,
      departId: null,
      userId: null,
      departShow: true,
      upRecords: [],
      form: {
        phone: null,
        keqing_remarks: "",
        historyRecord: null,
        choosePeople: null,
        customerScale: null,
        customerSource: null,
        createTime: null,
        changeTime: null,
        getTime: null,
        sendTime: "",
        activePhone: "",
        customerPhone: "",
        company: null,
        departName: null,
        remarks: "",
        industry: null,
        address: null,
        status: "",
        userId: null,
        departId: "",
        id: "",
        id1: null,
        roleId: "",
      },
      nums: [
        {
          id: 0,
          numName: "部门公海",
        },
        {
          id: 1,
          numName: "公司公海",
        },
      ],
      changeNums: null,
      numsArray: [], //选中号码的ID
      freeDepart: [],
      underDepart: [],
      underUser: [],
      remarks: "", //备注（只传不回显）
      numShow: false, //批量导入弹出框
      realname: null,
      indexArr: [],
      isBtn: false,
      hideNum: false,
      roleName: null,
      ms_username: null,
      xphone: null,
      times: 20,
      callVisible: false,
      onecallVisible: false,
      historyRecord: null,
      historys: true,
      tableData: [], //表格内绑定的数据
      pageTotal: 0, //页数
      pageIndex: 1, //当前在第几页
      pageSize: 10, //前端规定每页显示的数量
      page: 1, //第几页
      size: 10, //每页的数量
      cutIndex: 0,
      openedArr: [],
      customerSourceOptions: [],
      openedArrObj: {
        0: [],
        1: [],
      },
    };
  },
  created() {
    this.$nextTick(function () {
      this.ifParentId = localStorage.getItem("parentId");
      this.customerSourceOptions = JSON.parse(
        localStorage.getItem("customerSourceOptions")
      );
      let bool = localStorage.getItem("hideNum");
      bool == "false" ? (this.hideNum = false) : (this.hideNum = true);
      // console.log("hideNum参数", this.hideNum);
      setTimeout(() => {
        this.callRecord = false;
      }, 1500);
    });
    this.realname = localStorage.getItem("realname");
    this.roleName = localStorage.getItem("roleName");
    this.ms_username = localStorage.getItem("ms_username");
    this.id = localStorage.getItem("id") * 1;
    if (this.roleName == "管理员" || this.roleName == "企业") {
      this.findUser();
    }
    if (this.roleName == "组长") {
      this.findUser1();
      this.id1 = localStorage.getItem("id") * 1;
    }
    this.getList();
  },
  //切换界面就执行方法
  activated() {
    // this.newColumnsData = JSON.parse(localStorage.getItem("newColumnsData"));
    this.newColumnsData = JSON.parse(this.getCookie("columnsData"));
    this.$nextTick(() => {
      let propArr = [
        "customerPhone",
        "company",
        "choosePeople",
        "customerScale",
        "customerSource",
        "industry",
        "address",
      ];
      this.editColumnsData = this.newColumnsData.filter((v) => {
        return propArr.includes(v.prop);
      });
    });
  },
  watch: {
    departUser() {
      this.departId = null;
      this.userId = null;
    },

    openedArrObj: {
      handler(newVal) {
        if (newVal.length > 0) {
          this.$nextTick(() => {
            //newVal是新的表格数据，[8] 8是表格数据要默认选中的索引
            this.indexArr.forEach((item) => {
              this.$refs.multipleTable.toggleRowSelection(newVal[item], true);
            });
          });
        }
      },
      // immediate: true
    },
  },
  inject: ["reload"],
  methods: {
    hidenDistribute() {
      console.log("关闭了弹框");
      // this.distributeShow =false
    },
    async updateCallTime() {
      // this.form
      let params = {
        id: this.form.id,
      };
      let res = await update_call(params);
      // let res2012 = await update_call({ id: this.form.id });
      if (res.data.statusCode) {
        Message.success("更新成功");
        this.getList();
        this.updateVisible = false;
      } else {
        Message.error(res.data.message);
      }
      // return false
    },
    getCookie(name) {
      const cookieArr = document.cookie.split("; ");
      for (let i = 0; i < cookieArr.length; i++) {
        const cookiePair = cookieArr[i].split("=");
        if (cookiePair[0] === name) {
          return cookiePair[1];
        }
      }
      return JSON.stringify([]);
    },
    setisDivVisible(bool) {
      if (!bool) {
        this.customerSource = null;
        this.company = null;
        this.id1 = null;
        this.departId = null;
        this.userId = null;
        this.createTime = null;
      }
      this.isDivVisible = bool;
    },
    goBack() {
      this.$router.go(-1);
    },
    getNewLabel(oldLabel, aprop) {
      // console.log("this.newColumnsData", this.newColumnsData);
      let name = oldLabel;
      this.newColumnsData.forEach((v) => {
        if (v.prop == aprop) {
          name = v.label;
        }
      });
      return name;
    },
    // 筛选禁止复制的公司
    filterEnterprise() {
      // let arr = [2]
      let arr = [5805];
      if (arr.includes(localStorage.getItem("parentId") * 1)) {
        return "no-select";
      }
    },
    // 导入号码的关闭事件
    clearNumber() {
      this.fileList = [];
      this.file = {};
      this.$refs.upload.clearFiles();
    },
    // 双击弹出框
    dblclick(row) {
      this.form = { ...row };
      this.update_status = null;
      this.updateVisible = true;
    },
    // 挂断
    hang_up() {
      if (this.ua) {
        this.ua.stop();
      }
    },
    // sip挂断关闭弹框
    showMethod(obj) {
      this.sipCallVisible = obj.bool;
      this.ua = obj.ua;
    },
    // 编辑关闭回调
    handleClose(done) {
      this.$confirm("如需关闭弹框，请挂断SIP通话")
        .then((_) => {
          // done();
        })
        .catch((_) => { });
    },
    // 双击切换事件
    handleClick(e) {
      if (e.index == "1") {
        this.record();
      }
    },
    // 分页
    handlePageChange(val) {
      this.page = val;
      this.getList();
    },
    handleSizeChange(val) {
      this.size = val;
      this.getList();
    },

    // 转换日期
    getYMDHMS(format, date) {
      if (!date) {
        return "";
      }
      date = new Date(date);
      const dataItem = {
        "Y+": date.getFullYear().toString(),
        "m+": (date.getMonth() + 1).toString(),
        "d+": date.getDate().toString(),
        "H+": date.getHours().toString(),
        "M+": date.getMinutes().toString(),
        "S+": date.getSeconds().toString(),
      };
      Object.keys(dataItem).forEach((item) => {
        const ret = new RegExp(`(${item})`).exec(format);
        if (ret) {
          format = format.replace(
            ret[1],
            ret[1].length === 1
              ? dataItem[item]
              : dataItem[item].padStart(ret[1].length, "0")
          );
        }
      });
      return format;
    },

    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    //选择table表格
    handleSelectionChange(val) {
      this.numsArray = [];
      val.forEach((item) => {
        this.numsArray.push(item.id);
      });
      // console.log(this.numsArray);
    },
    //查询用户下所有员工
    findUser() {
      let params = {};
      let res = phoneFindUser(params);
      res.then((res) => {
        this.underDepart = res.data.data.depart;
        this.underUser = res.data.data.user;
      });
    },
    //释放具体部门
    findUser2() {
      // let gsId = localStorage.getItem("id") * 1;
      let bmId = localStorage.getItem("parentId") * 1;
      let params = {
        topId: "",
      };
      if (this.roleName == "公司") {
        // params.topId = gsId;
      } else {
        params.topId = bmId;
      }
      let res = sysDepartList(params);
      res.then((res) => {
        let data = res.data.data;
        for (let key in data) {
          this.depId = data[key].id;
          this.freeDepart = data;
        }
      });
    },

    findUser1() {
      let params = {};
      let res = phoneFindUser(params);
      res.then((res) => {
        this.underDepart = res.data.data.depart;
        this.underUser = res.data.data.user;
      });
    },

    // 补0
    fillZero(n) {
      return n <= 9 ? "0" + n : n;
    },

    // 获取年月日的函数
    getYmd(data) {
      let y = data.getFullYear(); //年
      let m = data.getMonth() + 1; //月
      let d = data.getDate(); //日
      return [y, this.fillZero(m), this.fillZero(d)].join("-");
    },

    // 获取时间的函数
    getHms(times) {
      let h = times.getHours(); //时
      let m = times.getMinutes(); //分
      let s = times.getSeconds(); //秒
      return [this.fillZero(h), this.fillZero(m), this.fillZero(s)].join(":");
    },
    //搜索
    handleSearch() {
      // alert(111)
      let obj = {};
      let obj1 = {};
      let obj2 = {};

      // 转换日期
      let sta_createTime = null;
      let end_createTime = null;
      if (this.createTime) {
        sta_createTime =
          this.getYmd(this.createTime[0]) +
          " " +
          this.getHms(this.createTime[0]);
        end_createTime =
          this.getYmd(this.createTime[1]) +
          " " +
          this.getHms(this.createTime[1]);
      }

      let sta_changeTime = null;
      let end_changeTime = null;
      if (this.changeTime) {
        sta_changeTime =
          this.getYmd(this.changeTime[0]) +
          " " +
          this.getHms(this.changeTime[0]);
        end_changeTime =
          this.getYmd(this.changeTime[1]) +
          " " +
          this.getHms(this.changeTime[1]);
      }

      let sta_getTime = null;
      let end_getTime = null;
      if (this.getTime) {
        sta_getTime =
          this.getYmd(this.getTime[0]) + " " + this.getHms(this.getTime[0]);
        end_getTime =
          this.getYmd(this.getTime[1]) + " " + this.getHms(this.getTime[1]);
      }

      this.page = 1;
      let params = {
        // bsf: 1,
        page: this.page,
        size: this.size,
        activePhone: this.activePhone,
        company: this.company,
        industry: this.industry,
        address: this.address,
        // createTime: this.createTime,
        sta_createTime: sta_createTime,
        end_createTime: end_createTime,
        changeTime: this.changeTime,
        getTime: this.getTime,
        choosePeople: this.choosePeople,
        customerScale: this.customerScale,
        customerSource: this.customerSource,
        remarks: this.notes,
      };

      if (this.roleName == "管理员" || this.roleName == "企业") {
        params.depart_id = this.departId;
        params.userId = this.userId;
      } else {
        params.userId = this.id1;
      }

      if (this.cutIndex == 1) {
        params.yesCall = "1";
      } else {
        params.noCall = "1";
      }

      if (!params.activePhone) params.activePhone = null;
      let res = phoneList(params);
      // let res1 = phoneFindUser({});
      res.then((a) => {
        let data = a.data;
        if (data.statusCode == "50000") {
          // this.cutIndex = oldIndex
          // data = res.data.data
          Message.warning(data.message);
          return false;
        }
        this.openedArr = [];

        if (data.data) {
          this.openedArrObj[this.cutIndex] = data.data;
          this.openedArrObj[this.cutIndex].pageTotal = data.total;
          this.pageTotal = data.total;
        }

        a.data.data.forEach((item) => {
          let str = item.activePhone;
          item.phone = str.substring(0, 3) + "****" + str.substring(7);
        });
      });
      // res1.then((b) => {
      //   this.underDepart = b.data.data.depart;
      //   this.underUser = b.data.data.user;
      // });
    },
    //重置
    async reset() {
      // (this.createTime = null),
      //   (this.changeTime = null),
      //   (this.getTime = null),
      //   (this.activePhone = null);
      // this.userId = null;
      // this.departId = null;
      // this.value = null;
      // this.changeNums = null;
      // this.company = null;
      // this.id1 = null;
      // this.getList();
      this.notes = null;
      this.activePhone = null;
      this.userId = null;
      this.id1 = null;
      this.customerSource = null;
      this.company = null;
      this.industry = null;
      this.departId = null;
      this.createTime = null;
      this.getList();
      // this.reload();
    },
    //分配号码
    distributeNums() {
      this.findUser();
      this.departId = null;
      this.userId = null;
      this.distributeShow = true;
    },
    //分配号码确认提交
    async distributeSubmit() {
      Message.warning("分配号码中,请稍等...");
      this.distributeShow = false;

      let newArr = this.tableData.filter(
        (v) => this.numsArray.includes(v.id) && v.status != 4
      );
      let backArr = [];
      backArr = this.tableData.filter(
        (v) => this.numsArray.includes(v.id) && v.status == 4
      );
      let listId = newArr.map((v) => v.id);

      if (backArr.length != 0) {
        Message.warning(
          `不能将黑名单客户分配给员工,本次过滤了${backArr.length}个黑名单客户`
        );
      }
      console.log("黑名单", backArr);

      if (this.userId == "" || this.userId == null) {
        let params = {
          listId,
          departId: this.departId,
        };
        let res = await phoneDistribute(params);
        if (res.data.statusCode == "00000") {
          Message.success(res.data.message);
        } else {
          Message.error(res.data.message);
        }
      } else {
        let params = {
          listId: this.numsArray,
          userId: this.userId,
        };
        let res = await phoneDistribute(params);
        if (res.data.statusCode == "00000") {
          Message.success(res.data.message);
          this.numsArray = [];
        } else {
          Message.error(res.data.message);
          this.numsArray = [];
        }
      }
      this.userId = null;
      this.departId = null;
      this.getList();
    },
    // 展示
    async getList(e) {
      // 转换日期
      let sta_createTime = null;
      let end_createTime = null;
      if (this.createTime) {
        sta_createTime =
          this.getYmd(this.createTime[0]) +
          " " +
          this.getHms(this.createTime[0]);
        end_createTime =
          this.getYmd(this.createTime[1]) +
          " " +
          this.getHms(this.createTime[1]);
      }

      let sta_changeTime = null;
      let end_changeTime = null;
      if (this.changeTime) {
        sta_changeTime =
          this.getYmd(this.changeTime[0]) +
          " " +
          this.getHms(this.changeTime[0]);
        end_changeTime =
          this.getYmd(this.changeTime[1]) +
          " " +
          this.getHms(this.changeTime[1]);
      }

      let sta_getTime = null;
      let end_getTime = null;
      if (this.getTime) {
        sta_getTime =
          this.getYmd(this.getTime[0]) + " " + this.getHms(this.getTime[0]);
        end_getTime =
          this.getYmd(this.getTime[1]) + " " + this.getHms(this.getTime[1]);
      }
      let params = {
        // bsf: 1,
        page: this.page,
        size: this.size,
        activePhone: this.activePhone,
        company: this.company,
        industry: this.industry,
        address: this.address,
        createTime: this.createTime,
        sta_createTime: sta_createTime,
        end_createTime: end_createTime,
        changeTime: this.changeTime,
        getTime: this.getTime,

        choosePeople: this.choosePeople,
        customerScale: this.customerScale,
        customerSource: this.customerSource,
      };

      if (this.roleName == "管理员" || this.roleName == "企业") {
        params.depart_id = this.departId;
        params.userId = this.userId;
      } else {
        params.userId = this.id1;
      }

      if (this.cutIndex == 1) {
        params.yesCall = "1";
      } else {
        params.noCall = "1";
      }
      if (!params.activePhone) params.activePhone = null;

      let res = await phoneList(params);
      let data = res.data;
      if (data.statusCode == "50000") {
        // this.cutIndex = oldIndex
        // data = res.data.data
        Message.warning(data.message);
      }

      if (data.data) {
        this.openedArrObj[this.cutIndex] = data.data;
        this.openedArrObj[this.cutIndex].pageTotal = data.total;
        this.pageTotal = data.total;
      }

      res.data.data.forEach((item) => {
        let str = item.activePhone;
        item.phone = str.substring(0, 3) + "****" + str.substring(7);
      });
    },
    // 展示切换
    async clickData(e) {
      this.cutIndex = e.index;
      if (this.openedArr.includes(this.cutIndex)) {
        this.pageTotal = this.openedArrObj[this.cutIndex].pageTotal;
        return false;
      }

      // 转换日期
      let sta_createTime = null;
      let end_createTime = null;
      if (this.createTime) {
        sta_createTime =
          this.getYmd(this.createTime[0]) +
          " " +
          this.getHms(this.createTime[0]);
        end_createTime =
          this.getYmd(this.createTime[1]) +
          " " +
          this.getHms(this.createTime[1]);
      }

      let sta_changeTime = null;
      let end_changeTime = null;
      if (this.changeTime) {
        sta_changeTime =
          this.getYmd(this.changeTime[0]) +
          " " +
          this.getHms(this.changeTime[0]);
        end_changeTime =
          this.getYmd(this.changeTime[1]) +
          " " +
          this.getHms(this.changeTime[1]);
      }

      let sta_getTime = null;
      let end_getTime = null;
      if (this.getTime) {
        sta_getTime =
          this.getYmd(this.getTime[0]) + " " + this.getHms(this.getTime[0]);
        end_getTime =
          this.getYmd(this.getTime[1]) + " " + this.getHms(this.getTime[1]);
      }
      let params = {
        // bsf: 1,
        page: this.page,
        size: this.size,
        activePhone: this.activePhone,
        company: this.company,
        industry: this.industry,
        address: this.address,
        // createTime: this.createTime,
        sta_createTime: sta_createTime,
        end_createTime: end_createTime,
        changeTime: this.changeTime,
        getTime: this.getTime,
        choosePeople: this.choosePeople,
        customerScale: this.customerScale,
        customerSource: this.customerSource,
      };

      if (this.roleName == "管理员" || this.roleName == "企业") {
        params.depart_id = this.departId;
      } else {
        params.userId = this.id1;
      }

      if (this.cutIndex == 1) {
        params.yesCall = "1";
      } else {
        params.noCall = "1";
      }
      if (!params.activePhone) params.activePhone = null;

      console.log("this.cutIndex", this.cutIndex);
      let res = await phoneList(params);
      let data = res.data;
      if (data.statusCode == "50000") {
        // this.cutIndex = oldIndex
        // data = res.data.data
        Message.warning(data.message);
        return false;
      }

      if (data.data) {
        this.openedArrObj[this.cutIndex] = data.data;
        this.openedArrObj[this.cutIndex].pageTotal = data.total;
        this.pageTotal = data.total;
      }

      this.openedArr.push(e.index);
      res.data.data.forEach((item) => {
        let str = item.activePhone;
        item.phone = str.substring(0, 3) + "****" + str.substring(7);
      });
    },
    // 编辑关闭回调
    updateClose(done) {
      this.$confirm("点击编辑页的确定关闭弹出框")
        .then((_) => {
          done();
        })
        .catch((_) => { });
    },
    async yesCall(row) {
      if (this.callButton) {
        Message.warning("请稍等几秒再拨打~");
        return false;
      }

      // const loading = await this.$loading({
      //   lock: true,
      //   text: "即将拨打,请稍等...",
      // });

      this.form = row.row;
      this.update_status = null;
      let parentId = JSON.parse(localStorage.getItem("parentId"));
      this.sipPhone = row.row.activePhone;
      let params = {
        passivePhone: this.sipPhone,
        activePhone: this.ms_username,
        parentId,
        phoneId: row.row.id,
      };
      let res = callAppBind(params);
      console.log("callAppBind", res);
      res.then((res) => {
        this.callData = res;
        if (res.data.statusCode == "00000") {
          this.getList();
          // loading.close();
          Message.success(res.data);
          //判断是否是AXB呼叫模式
          if (res.data.data.sip) {
            // console.log("sipsip");
            this.sipCallVisible = true;
            // this.callNum;
          } else if (res.data != null && !res.data.data.sip) {
            // console.log(res.data.data);
            this.xphone = res.data.data;
            this.callVisible = true;
            this.countTime();
            this.update(row);
          } else {
            Message.success("请注意接听手机电话！");
          }
        } else {
          // loading.close();
          // Message.error(res.data);
          let data = res.data.data;
          if (data) {
            Message.error(JSON.parse(data).message);
          } else {
            Message.error(res.data.message);
          }
        }
        this.callButton = true;
        this.tiemID = setTimeout(() => {
          this.callButton = false;
        }, 5000);
      });
    },
    // 数组对象去重
    quchogn(arrObj) {
      let map = new Map();
      for (let item of arrObj) {
        if (!map.has(item.id)) {
          map.set(item.id, item);
        }
      }
      let arr = [...map.values()];
      console.log("arr", arr);
    },
    call(row) {
      this.callRecordTableData = [...new Set(this.callRecordTableData)];
      if (this.callRecordTableData.length > 3) {
        this.callRecordTableData.pop();
      }
      this.callRecordTableData.unshift(row.row);
      this.callRecordTableData = [...new Set(this.callRecordTableData)];
      if (true) {
        this.yesCall(row);
      } else {
        this.$confirm("是否使用号码检测?", "提示", {
          confirmButtonText: "使用号码检测",
          cancelButtonText: "直接拨号",
          type: "warning",
          distinguishCancelAndClose: true, // 重要，设置为true才会把右上角X和取消区分开来
          beforeClose: (action, instance, done) => {
            console.log("action", action);
            if (action === "cancel") {
              this.yesCall(row);
            }
            done();
          },
        })
          .then(async () => {
            let params = {
              phone: row.row.activePhone + "",
            };
            let res = await find(params);
            if (res.data.statusCode == "00000") {
              let status = res.data.data[0].status;
              if (status == 1) {
                Message.success("有效号码");
                await this.yesCall(row);
              } else if (status == 2) {
                Message.error("无效号码");
              } else {
                Message.info("未知");
                await this.yesCall(row);
              }
            } else {
              Message.error(res.data.message);
            }
          })
          .catch(() => {
            // this.yesCall(row);
          });
      }
    },
    //拨打按钮
    callDa(row) {
      // 需要确认有无这些文件导入 (selectDzwlUserApi和confirmUserOnline接口,Message插件)
      //根据用户IP 返回城市级别的定位结果
      let native = new BMap.LocalCity();
      native.get(async (res) => {
        let params = {
          companyId: localStorage.getItem("parentId"),
          userId: localStorage.getItem("id"),
        };
        // 确认在不在白名单
        let res1 = await selectDzwlUserApi(params);
        let data1 = res1.data.data;
        if (data1.length != 0) {
          // 可以拨打的地方
          this.callDa(row);
        } else {
          // 确定用户在不在经纬度接口
          params = {
            userLongitude: res.center.lng, //围栏圆心经度
            userLatitude: res.center.lat, ////围栏圆心纬度
            companyId: localStorage.getItem("parentId"), // 公司id
            userId: localStorage.getItem("id"), // 用户id
          };
          let res2 = await confirmUserOnline(params);
          if (res2.data.statusCode == "00000") {
            // 可以拨打的地方
            this.callDa(row);
          } else {
            Message.warning("你当前的位置不在公司围栏内!");
            return false;
          }
        }
      });
    },
    //axb呼叫模式弹窗
    countTime() {
      if (this.times > 0) {
        setTimeout(() => {
          this.times -= 1;
          this.countTime();
        }, 1000);
      } else {
        this.callVisible = false;
      }
    },
    // 释放按钮
    removeButton(data, index) {
      if (data.status == 3) {
        Message.warning("此号码为成交客户，无法释放");
        return;
      } else if (data.status == 4) {
        Message.warning("此号码为黑名单，无法释放");
        return;
      }
      this.removeIndex = index;
      let params = {
        listId: [data.id],
        roleId: data.roleId,
        departId: data.departId,
      };
      this.removeParams = params;
      this.removeVisible = true;
    },
    // 下拉事件
    oneSlt() {
      if (this.removeValue == 0) {
        this.disabled1 = false;
      } else {
      }
    },
    // 批量下拉事件
    oneSltBatch() {
      if (this.batchRemoveValue == 0) {
        this.disabled2 = false;
      } else {
      }
    },
    // 释放 公司
    confirm() {
      // 没有部门id，就去公司公海
      this.removeParams.departId = "";
      let res = phoneDelete(this.removeParams);
      res.then((res) => {
        Message.success("释放成功");
        this.openedArrObj[this.cutIndex].splice(this.removeIndex, 1);
        this.removeVisible = false;
        this.getList();
      });
    },
    // 释放 部门
    step() {
      let res = phoneDelete(this.removeParams);
      res.then((res) => {
        Message.success("释放成功");
        this.openedArrObj[this.cutIndex].splice(this.removeIndex, 1);
        this.getList();
      });
    },
    // 释放确认事件
    submitRemoveValue() {
      if (this.removeValue === "") return;
      // console.log(this.removeParams.departId);
      // 有部门id，就去部门
      if (this.removeValue === 0) {
        this.step();
      } else if (this.removeValue === 1) {
        this.confirm();
      } else {
      }
    },
    // 查询具体部门
    depDetails() {
      this.depVisible = true;
      this.removeVisible = false;
      this.findUser2();
    },
    // 批量查询具体部门
    depDetails1() {
      this.batchRemoveVisible = false;
      this.depVisibleBatch = true;
      this.findUser2();
    },
    // 批量释放按钮
    moreDelete() {
      if (this.numsArray.length == 0) {
        Message.warning("未选中号码");
        return;
      }
      this.batchRemoveVisible = true;
    },
    // 释放 公司
    confirm1() {
      let params = {
        listId: this.numsArray,
        roleId: this.openedArrObj[this.cutIndex][0].roleId,
        departId: "",
      };
      this.batchRemoveVisible = false;
      let res = phoneDelete(params);
      res.then((res) => {
        Message.success("释放成功");
        this.numsArray.map((v, i) => {
          let index = this.openedArrObj[this.cutIndex].findIndex(
            (item) => item.id === v
          );
          this.openedArrObj[this.cutIndex].splice(index, 1);
          // this.numsArray.splice(i, 1);
        });
        this.getList();
      });
    },
    // 释放 部门
    step1() {
      let params = {
        listId: this.numsArray,
        roleId: this.openedArrObj[this.cutIndex][0].roleId,
        departId: this.departId,
      };
      this.depVisibleBatch = false;
      let res = phoneDelete(params);
      res.then((res) => {
        Message.success("释放成功");
        this.numsArray.map((v, i) => {
          let index = this.openedArrObj[this.cutIndex].findIndex(
            (item) => item.id === v
          );
          this.openedArrObj[this.cutIndex].splice(index, 1);
          // this.numsArray.splice(i, 1);
        });
        this.getList();
      });
    },
    // 批量释放确认事件
    submitBatchRemoveValue() {
      this.numsArray.map((v, i) => {
        this.openedArrObj[this.cutIndex].findIndex((item) => {
          if (item.status == 3 || item.status == 4) {
            Message.warning("选中列表中包含成交客户或黑名单，无法释放");
            return;
          }
        });
      });
      if (this.batchRemoveValue === "") return;
      if (this.batchRemoveValue === 0) {
        this.step1();
      } else if (this.batchRemoveValue === 1) {
        this.confirm1();
      } else {
      }
      setTimeout(function () {
        location.reload();
      }, 1000);
    },
    //编辑提交
    updateSubmit() {
      if (this.ifParentId == 7) {
        if (!this.form.customerSource) {
          Message.warning("客户来源不能为空~");
          return false;
        }
      }
      if (this.update_status == null) {
        Message.warning("请选择状态!!!");
      } else {
        let params = {
          historyRecord: this.form.historyRecord,
          activePhone: this.form.activePhone,
          customerPhone: this.form.customerPhone,
          company: this.form.company,
          remarks: this.form.remarks,
          status: this.update_status,
          userId: this.form.userId,
          departId: this.form.departId,
          id: this.form.id,
          roleId: this.form.roleId,
          choosePeople: this.form.choosePeople,
          customerScale: this.form.customerScale,
          customerSource: this.form.customerSource,
          industry: this.form.industry,
          address: this.form.address,
        };
        let res = phoneUpdate(params);
        res.then((res) => {
          if (res.data.statusCode == "00000") {
            Message.success(res.data.message);
            this.openedArr = [];
            this.updateVisible = false;
            // this.reload();
            this.getList();
          } else {
            Message.error(res.data.message);
            this.updateVisible = false;
            this.getList();
          }
        });
      }
    },
    //下载模板
    upload() {
      window.open("http://124.70.206.131:9005/phone.xls", "_self");
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    handleExceed(files, fileList) {
      Message.warning(
        `当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length
        } 个文件`
      );
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定释放 ${file.name}？`);
    },
    compareArrays(arr1, arr2) {
      return JSON.stringify(arr1) === JSON.stringify(arr2);
    },
    changeUpload(file, fileList) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        console.log("jsonData", jsonData);
        // 获取表头行数据
        const headerRow = jsonData[0];
        let titleArr = [
          "公司",
          "客户姓名",
          "手机号",
          "客户分类",
          "备注",
          "坐席账户",
          "部门名字",
          "行业",
          "地址",
        ];
        console.log(
          "headerRow",
          headerRow,
          this.compareArrays(headerRow, titleArr)
        );
        // 根据您的需求编写表头校验逻辑
        if (this.compareArrays(headerRow, titleArr)) {
          this.file = file.raw;
          // resolve();
        } else {
          setTimeout(() => {
            this.file = {};
            this.$refs.uplaod.clearFiles();
          }, 1000);
          this.$message.error("请勿修改表头!");
        }
      };
      reader.onerror = () => {
        this.$message.error("读取文件失败!");
      };
      reader.readAsArrayBuffer(file.raw);
    },
    //批量导入按钮
    allNums() {
      this.disabled = false;
      this.numShow = true;
      this.formDate = null;
    },
    //批量导入提交
    async numsSubmit(index) {
      console.log("导入前限制大小", this.file);
      const isLt2M = this.file.size / 1024 / 1024 < 3;

      if (!this.file.size) {
        this.$message.warning("上传的文件不能为空!");
        return false;
      } else if (!isLt2M) {
        this.$message.error("上传文件大小不能超过 3MB!");
        return false;
      }

      console.log("导入前限制大小", XlsxPopulate);
      let formDate = new FormData();
      // 完整上传
      formDate.append("file", this.file);
      Message.success("数据加载中，稍等！请勿离开页面");

      const loading = await this.$loading({
        lock: true,
        text: "导入号码中,请稍等...",
      });

      if (this.numberTest) {
        formDate.append("phoneTest", 1);
      }
      // phoneImportPhoneOld 旧版
      // phoneImportPhone 新版
      let res;
      if (this.ms_username == "15523987815") {
        res = await phoneImportPhoneOld(formDate);
      } else {
        res = await phoneImportPhone(formDate);
      }
      if (res.data.statusCode == "00000") {
        loading.close();
        Message.success(res.data.message);
        this.file = {};
      } else {
        loading.close();
        Message.error(res.data.message);
        this.file = {};
      }
      this.disabled = true;
      this.numShow = false;
    },
    //添加号码
    addNums() {
      this.form = [];
      this.update_status = null;
      this.addOneVisible = true;
    },
    //添加号码确认
    addOneSubmit() {
      if (this.ifParentId == 7) {
        if (!this.form.customerSource) {
          Message.warning("客户来源不能为空~");
          return false;
        }
      }
      if (!this.form.activePhone) {
        Message.warning("客户号码不能为空~");
        return false;
      }
      let params = {
        activePhone: this.form.activePhone,
        customerPhone: this.form.customerPhone,
        company: this.form.company,
        departName: this.form.departName,
        remarks: this.form.remarks,
        status: this.update_status,
        userId: this.id,
        choosePeople: this.form.choosePeople,
        customerScale: this.form.customerScale,
        customerSource: this.form.customerSource,
        industry: this.form.industry,
        address: this.form.address,
      };
      let res = phoneAdd(params);
      res.then((res) => {
        if (res.data.statusCode == "00000") {
          Message.success(res.data.message);
          this.addOneVisible = false;
        } else {
          Message.error(res.data.message);
          this.addOneVisible = false;
        }
        this.getList();
      });
    },
  },
};
</script>

<style lang="less" scoped>
.vipSearch {
  // padding: 20px 0;
  // padding-right: 20px;
  // box-sizing: border-box;
  // box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.3);
  // border-radius: 7px;
}

.box-card {
  position: absolute;
  top: 2%;
  right: 2%;
  z-index: 10;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.6) !important;

  /deep/.el-card__header {
    padding: 10px 20px;
  }
}

.no-select {
  -moz-user-select: none;
  /* Firefox私有属性 */
  -webkit-user-select: none;
  /* WebKit内核私有属性 */
  -ms-user-select: none;
  /* IE私有属性(IE10及以后) */
  -khtml-user-select: none;
  /* KHTML内核私有属性 */
  -o-user-select: none;
  /* Opera私有属性 */
  user-select: none;
  /* CSS3属性 */
}

/deep/.el-button--success,
.el-button--primary {
  color: #fff;
  background-color: #3278fe;
  border-color: #3278fe;

  &:hover {
    opacity: 0.7;
  }
}

#creatNums {
  height: 100%;
  display: flex;
  flex-direction: column;

  .el-card {
    flex: 1;
    box-shadow: none;
    overflow-y: scroll;
  }

  .num-upload {
    /deep/ .el-dialog__body {
      display: flex;
      justify-content: space-between;

      .left {
        margin-right: 8px;
      }

      .right {
        flex: 1;
      }
    }
  }

  header {
    h1 {
      font-weight: normal;

      >span {
        font-size: 18px;
        color: #606369;
      }
    }

    .search {
      display: flex;
      margin-top: 10px;
      flex-direction: column;

      .left {
        display: flex;
        align-items: center;
        height: 100%;
        flex-wrap: wrap;
        line-height: 30px;

        .left-wrap {
          width: 20%;
          display: flex;
          align-items: center;

          .el-date-editor {
            width: 100% !important;
          }

          .el-select {
            width: 100%;
          }

          span {
            margin: 6px;
            width: 70px;
            text-align: right;
            align-items: center;
            font-size: 12px;
          }
        }
      }

      .right {
        margin-top: 10px;
        // text-align: right;
        display: flex;
        /* justify-content: space-between; */
        justify-content: center;
        align-items: center;
        height: 100%;
      }
    }
  }

  // main {
  .pagination {
    width: 100%;
    // height: 40px;
    // border: 1px solid red;
    background-color: #fff;
    // bottom: 0;
    // right: 0;
    // z-index: 99;
    text-align: right;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    padding: 10px 0;
    box-sizing: border-box;
  }

  // }

  .uploadBtn {
    width: 68%;
  }

  /deep/.el-dialog {
    width: 70%;
  }

  .update {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: flex-start;
    align-items: flex-start;

    .el-form-item {
      width: 50%;

      .el-form-item__content {
        .el-select {
          width: 100%;
        }
      }
    }
  }

  .client-details {
    /deep/.el-drawer.rtl {
      height: 40%;
      bottom: 0;
      top: auto;

      .update-details {
        width: 70%;
      }

      .data-details {
        width: 300px;

        .title {
          color: #fff;
          background-color: #53a1f0;
          padding: 10px 20px;
          box-sizing: border-box;
          font-size: 18px;
          text-align: center;
        }

        .users {
          font-size: 14px;
          line-height: 30px;
        }
      }
    }
  }

  .dblclick /deep/.el-dialog__header {
    padding: 0;
  }
}
</style>